/* Media Queries */

@media screen and (max-width: 414px){
    .nav-bar{
        padding-bottom: .5em;
    }
    .name-div{
        padding: 0;
    }
    .name-desc{
        padding: 1em;
    }
    .about-me-cont{
        padding: 15em 2.5em;
        padding-bottom: 9em;
    }
    .projects-links{
        font-size: 1.5em;
    }
}
@media screen and (min-width: 768px){
    .name-desc{
        font-size: 2em;
    }
    .about-me-resp{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .about-me-pic{
        width: 55%;
        padding: .5em;
    }
    #about-me-word{
        font-size: 3em;
        padding: 0;
    }
    
    .skills-cont{
        padding-bottom: 4em;
    }

    .skills-word{
        font-size: 3em;
    }

    .skills-area{
        padding: 5em;
    }

    .projects-word{
        font-size: 3em;
        padding-bottom: 1em;
    }

    .mood-link-word{
        font-size: 1.5em;
    }

    .contact-word{
        font-size: 3em;
    }

    .lets-connect{
        font-size: 2em;
    }

    .email-me{
        font-size: 1.5em;
    }

    .social-icons{
        width: 45%;
    }

    .footer-cont{
        font-size: 100%;
        padding: 3em 5em;
    }

}

@media screen and (min-width: 1024px){
    .nav-bar{
        padding: 2em 5em;
        padding-bottom: 1em;
    }
    .main-name{
        width: 50%;
    }
    .name-desc{
        font-size: 2em;
    }
    .about-me-pic{
        width: 50%;
        padding: .5em;
    }
    #about-me-desc{
        font-size: 1.5em;
    }
    .skills-area{
        padding-bottom: 10em;
        padding-left: 10em;
        padding-right: 10em;
    }
    .social-icons{
        width: 20%;
    }
}