*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li{
  list-style: none;
}

a{
  text-decoration: none;
  color: inherit;
}

/* MainScreen CSS */

.split-background{
  background-image: linear-gradient(#FFAB9B,white);
  position: absolute;
  width: 50%;
  left: 50%;
  height: 100%;
  z-index: -1;
}

.big-logo{
  position: absolute;
  top: 5em;
  z-index: -1;
  width: 50%;
}

.nav-bar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2em 3em;
}

.contact-btn{
  background: #FFAB9B;
  padding: 9px 25px;
  border: 1px solid;
  border-radius: 50px;
  color: white;
  cursor: pointer;
}

.contact-btn:hover{
  color:#FFAB9B;
  background-color: white;
}

.name-div{
  display: flex;
  justify-content: center;
  padding-top: 2em;
}

.main-name{
  width: 75%;
}

.name-desc{
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 4em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1em;
  color: #16354F;
}

/* End of MainScreen CSS */


/* About Me CSS */

.about-me-cont{
  padding: 20em 3em;
  padding-bottom: 9em;
}

.about-me-pic{
  width: 100%;
}

.about-me-area{
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: #16354F;
  text-align: center;
}

#about-me-word{
  font-weight: 700;
  font-size: 2em;
  padding: 1em;
}

#about-me-desc{
  text-align: left;
  font-size: 1em; 
}

p{
  margin: 16px;
}

/* End of About Me CSS */

/* Skills CSS */

.skills-cont{
  display: flex;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  color: #16354F;
  flex-direction: column;
  padding-bottom: 8em;
}

.skills-word{
  font-size: 2em;
  font-weight: 700;
  padding-bottom: .5em;
}

.skills-area{
  font-weight: 600;
  padding: 2.5em;
}

.skills-icon-first{
  display: flex;
  justify-content: space-between;
}

.skills-icon-second{
  display: flex;
  justify-content: space-between;
  padding-top: 2em;
}

.js-word{
  padding-top: .75em;
}

/* End of Skills CSS */

/* Projects CSS */

.projects-cont{
  display: flex;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  color: #16354F;
  flex-direction: column;
  padding-bottom: 8em;
}

.projects-word{
  font-size: 2em;
  font-weight: 700;
  padding-bottom: 2em;
}

.projects-links{
  display: flex;
  justify-content: center;
  flex-direction:column;
  font-size: 2em;
  font-weight: 500;
}

.apps-link-word{
  padding-bottom: 1em;
}

.apps-link:hover{
  cursor: pointer;
  text-decoration: underline;
}

.apps-pic{
  width: 80%;
  border: 4px solid;
  border-radius: 50px;
  border-color: white;
}

.apps-pic:hover{
  border-color: #16354F;
}

#recipe-app-link{
  padding-bottom: 3em;
}
/* End of Projects CSS */

/* Contact CSS */

.contact-cont{
  display: flex;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  color: #16354F;
  flex-direction: column;
}

.contact-word{
  font-size: 2em;
  font-weight: 700;
  padding-bottom: 1em;
}

.lets-connect{
  font-size: 1.5em;
  font-weight: 500;
  padding-bottom: 2em;
}

.email-me{
  display: flex;
  justify-content: center;
  padding-bottom: 3em;
  font-weight: 500;
}

.email-line:hover{
  text-decoration: underline;
}

.contact-area{
  display: flex;
  justify-content: space-around;
  padding: 1em;
  padding-bottom: 5em;
}

.social-icons{
  width: 50%;
}

/* End of Contact CSS */

/* Footer CSS */

.footer-cont{
  display: flex;
  justify-content: space-between;
  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
  font-size: 80%;
  font-style: italic;
  padding: 2em 2em;
  color: #16354F;
  background-color: rgb(252, 234, 231);
}

/* End of Footer CSS */


/* Animations */

.nav-animate{
  animation: fadeInDown 1.5s;
}

.big-logo{
  animation: fadeInLeft 4s;
}

.main-name{
  animation: fadeIn 2s;
  animation-timing-function: ease-in;
}

.name-desc{
  animation: fadeInUp 3s;
  animation-timing-function: ease-out;
}

/* End of Animations */